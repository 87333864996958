<template>
  <div class="works">
    <div class="container">
      <div class="row mb-3">
        <div :class="isMobile ? 'text-center' : null " class="col-12 col-md-8">
          <h5 class="mb-3">How it works</h5>
          <p>
            Simple steps to become a certified Niftyz Partner and start earning
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-12 col-md-3">
              <div class="box-work">
                  <h3>1</h3>
                  <p>
                      Apply to be selected for the next Partnerships Training cohort and complete the course.
                  </p>
              </div>
          </div>
          <div :class="isMobile ? 'mt-4' : null" class="col-12 col-md-1 d-flex align-items-center justify-content-center">
            <div class="circle">
              <i :class="isMobile ? 'fa-chevron-down' : 'fa-chevron-right'" class="fa-solid"></i>
            </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="box-work">
                  <h3>2</h3>
                  <p>
                      Receive your certified Niftyz Partner badge and join our Partnerships Discord Channel.
                  </p>
              </div>
          </div>
          <div :class="isMobile ? 'mt-4' : null" class="col-12 col-md-1 d-flex align-items-center justify-content-center">
            <div class="circle">
              <i :class="isMobile ? 'fa-chevron-down' : 'fa-chevron-right'" class="fa-solid"></i>
            </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="box-work">
                  <h3>3</h3>
                  <p>
                      Start promoting and earn commissions.
                  </p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "works",
    data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const app = this;
    if (window.innerWidth < 767) {
      app.isMobile = true;
    }
    window.addEventListener("resize", function () {
      if (window.innerWidth > 767) {
        app.isMobile = false;
      } else {
        app.isMobile = true;
      }
    });
  },
};
</script>

<style></style>
