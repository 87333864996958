<template>
  <div class="benefits">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 text-center">
          <h4>Benefits of Niftyz</h4>
          <h2>Our Promise to you</h2>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid corporis cum, animi magni ad commodi tempore enim voluptates rerum impedit reprehenderit iusto .</p> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/nft-icon_1.0.png" alt="" />
          <h4>Certified Partnership</h4>
          <p>
            Redeem your unique Niftyz Certification badge upon completion of the
            Partnerships Training Course.
          </p>
        </div>

        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/referrals-icon_1.0.png" alt="" />
          <h4>Lifetime commissions for referrals</h4>
          <p>
            Be awarded 15% of the value of a Pro account if bought through your
            referral, plus royalties on the transactions generated by the
            business you referred to Niftyz - for life.
          </p>
        </div>

        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/web3-icon_1.0.png" alt="" />
          <h4>Powering business transition to Web3</h4>
          <p>
            Pushing the boundaries of NFTs and building an inclusive market for
            businesses of all sizes, verticals and geo, whether they are in Web2
            or Web3.
          </p>
        </div>

        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/growth-economy-icon_1.0.png" alt="" />
          <h4>The start of a new Growth Economy</h4>
          <p>
            The Niftyz universe has a Growth-driven economy where registered
            users can finally monetize their input rather than their output,
            turning them into liquid and tradable NFTs.
          </p>
        </div>

        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/on-chain-icon_1.0.png" alt="" />
          <h4>Building a reputable ecosystem</h4>
          <p>
            Niftyz is committed to building a reputable Partner ecosystem. We are already working with some of the biggest names in Web3 such as Animoca Brands, Crypto Valley, Brinc and many more
          </p>
        </div>

        <div class="col-12 col-md-4 benefit">
          <img src="../../assets/benefit/vetted-community_1.0.png" alt="" />
          <h4>Vetted business community</h4>
          <p>
            Learn and grow as a partner in a safe and focussed environment and meet amazing partners through our vetted Discord Channel.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits",
};
</script>

<style></style>
