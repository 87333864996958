<template>
  <div class="about">
    <Header />
    <JumbotronAbout />
    <div class="bk-img bk-first-about"></div>
    <FirstOnChain />
    <div class="shadow"></div>
    <Works />
    <div class="second-shadow"></div>
    <Benefits />
    <Apply />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import JumbotronAbout from "@/components/About/JumbotronAbout.vue";
import FirstOnChain from "@/components/About/FirstOnChain.vue";
import Works from "@/components/About/Works.vue";
import Benefits from "@/components/About/Benefits.vue";
import Apply from "@/components/About/Apply.vue";
import Footer from "@/components/Footer.vue";

export default{
  name:"About",
  components: {
    Header,
    JumbotronAbout,
    FirstOnChain,
    Works,
    Benefits,
    Apply,
    Footer
  }
}
</script>
