<template>
  <div class="more">
      <img src="../../assets/partners.svg" alt="">
      <div class="text-more">
          <h3>Become a Certified <span>Partner</span> now!</h3>
          <p>Apply now to get selected for the next cohort - limited spaces are available per cohort </p>
          <div class="btn-more">
              <a href="https://tally.so/r/31AkgO" target="_blank">Apply Now</a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:"Apply"

}
</script>

<style>

</style>