<template>
  <div class="jumbotron">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 text-center">
          <img class="orange-logo" src="../../assets/partners.svg" alt="" />
        </div>
        <div class="col-12 col-md-6">
          <h3>Become a Certified <br> <span>Niftyz Partner</span></h3>
          <p>
            The Niftyz Partnerships Program includes a free training course which prepares you with industry and NFT specific knowledge to increase the adoption of Niftyz amongst businesses. Complete the training modules on our Discord channel and receive Niftyz badges, which verifies your status as an official partner. 
          </p>
          <div class="btn-more">
            <a href="https://tally.so/r/31AkgO" target="_blank">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JumbotronAbout",
};
</script>

<style></style>
