<template>
  <div class="chain">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>The <span>1st on-chain</span></h2>
          <h2 class="ms-5">referral program</h2>
        </div>
        <div class="col-12 col-md-6">
          <p>One of a kind referral programme:</p>
          <ul>
              <li>We don’t use cookies, but blockchain! You will be linked to your referrals on-chain (For Life) and earn when they mint or create a Pro Account.</li>
              <li>Royalty payments happen in real time. </li>
              <li>You can withdraw at any time in the crypto currency used by your referrals to mint the NFTs, just pay gas fees for each withdrawal.</li>
              <li>Monitor your referrals stats through the Niftyz platform</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstOnChain",
};
</script>

<style></style>
